import React from 'react';
import { Link } from 'gatsby';

import './EmptyCart.scss';

const EmptyCart: React.FC = () => {
  return (
    <div className="empty-cart">
      <h2 className="empty-cart-title">Ваша корзина поки порожня</h2>
      <p className="empty-cart-text">
        Ви можете почати свої покупки
        <Link className="empty-cart-link" to="/catalog">
          тут
        </Link>
      </p>
    </div>
  );
};

export default EmptyCart;
