import React from 'react';
import { ICartModalProps } from './Types';
import { TRANSLATE } from '../../constants/languages';

const CartModal: React.FC<ICartModalProps> = ({ status }) => {
  const { successOrder, failedOrder } = TRANSLATE.ua;
  return (
    <div>
      <h2>{status === 'success' ? successOrder : failedOrder}</h2>
    </div>
  );
};

export default CartModal;
