import React, { useCallback, useContext } from 'react';

import './CartProductsList.scss';

import context from '../../context/context';
import { CART } from '../../constants/languages';
import CartProduct from './CartProduct';
import { IProductTypes } from './Types';

interface CardProductsInteface {
  callback?: () => void;
}

const CartProductsList: React.FC<CardProductsInteface> = ({ callback }) => {
  const { language, setProducts, products } = useContext(context);

  const handleRemoveProduct = useCallback(
    (removedPropduct: IProductTypes) => {
      const updatedProducts = products.filter(product => product.id !== removedPropduct.id);

      const cockpitLabel = 'Cockpit__Product__';
      const formattedId = removedPropduct.id.replace(cockpitLabel, '').split('_')[0];

      setProducts(() => updatedProducts);
      localStorage.setItem('products', JSON.stringify(updatedProducts));

      if (typeof window !== 'undefined' && (window as any).dataLayer) {
        (window as any).dataLayer.push({ ecommerce: null });
        (window as any).dataLayer.push({
          event: 'remove_from_cart',
          ecommerce: {
            items: [
              {
                item_name: removedPropduct.name,
                item_id: formattedId,
                price: removedPropduct.price,
                item_brand: removedPropduct.brand,
                item_category: removedPropduct.category,
                item_variant: '',
                quantity: removedPropduct.amount,
              },
            ],
          },
        });
      }
    },
    [products]
  );

  const handleChangeAmount = useCallback((updatedProduct: IProductTypes, type: 'dec' | 'inc') => {
    if (typeof window !== 'undefined' && (window as any).dataLayer) {
      const cockpitLabel = 'Cockpit__Product__';
      const formattedId = updatedProduct.id.replace(cockpitLabel, '').split('_')[0];

      (window as any).dataLayer.push({ ecommerce: null });
      (window as any).dataLayer.push({
        event: type === 'dec' ? 'remove_from_cart' : 'add_to_cart',
        ecommerce: {
          items: [
            {
              item_name: updatedProduct.name,
              item_id: formattedId,
              price: updatedProduct.price,
              item_brand: updatedProduct.brand,
              item_category: updatedProduct.category,
              item_variant: '',
              quantity: 1,
            },
          ],
        },
      });
    }
    setProducts(prev =>
      prev.map(product =>
        product.id === updatedProduct.id
          ? {
              ...product,
              amount: type === 'inc' ? product.amount + 1 : product.amount - 1,
            }
          : product
      )
    );
  }, []);

  const handleCurrentMeasure = useCallback((id: string, measure: string) => {
    setProducts(prev =>
      prev.map(product =>
        product.id === id
          ? {
              ...product,
              currentMeasure: measure,
            }
          : product
      )
    );
  }, []);

  const totalSum = products.reduce((acc, current) => {
    const measurePrice = new Map();

    const { stock } = current;
    measurePrice.set(
      current.measurment,
      stock === 1 ? +current.price.replace(',', '.') : (+current.price.replace(',', '.') / 100) * (100 - stock)
    );
    measurePrice.set(
      current.measurment2,
      stock === 1 ? +current.price2.replace(',', '.') : (+current.price2.replace(',', '.') / 100) * (100 - stock)
    );
    measurePrice.set(
      current.measurment3,
      stock === 1 ? +current.price3.replace(',', '.') : (+current.price3.replace(',', '.') / 100) * (100 - stock)
    );
    measurePrice.set(
      current.measurment4,
      stock === 1 ? +current.price4.replace(',', '.') : (+current.price4.replace(',', '.') / 100) * (100 - stock)
    );

    return acc + (+measurePrice.get(current.currentMeasure) || +current.price) * +current.amount;
  }, 0);

  return (
    <div className="cart-products">
      <h2 className="cart-products-title">{CART[language as 'ru' | 'ua'].inOrder}</h2>
      <div className="cart-products-wrapper">
        {products.map(product => (
          <CartProduct
            key={product.id}
            product={product}
            onAmountChange={handleChangeAmount}
            onCurrentMeasureChange={handleCurrentMeasure}
            handleRemoveProduct={handleRemoveProduct}
          />
        ))}
        <div className="cart-products-total">
          <p className="cart-products-total-text">
            {CART[language as 'ru' | 'ua'].totalPay}
            <span className="cart-products-total-amount">{totalSum.toFixed(2)} грн</span>
          </p>
          <button type="button" className="cart-products-order-btn" onClick={callback}>
            {CART[language as 'ru' | 'ua'].orderBtn}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartProductsList;
